import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Fonts

import './assets/fonts/AvertaCY-Light.woff2';
import './assets/fonts/AvertaCY-Regular.woff2';
import './assets/fonts/AvertaCY-Semibold.woff2';
import './assets/fonts/AvertaCY-Bold.woff2';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('appMount')
);

reportWebVitals();

import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import { useCookies } from 'react-cookie';

import Banner from './components/Banner'
import Contact from './components/Contact';
import IntroOverlay from './components/IntroOverlay';
import Modal from './components/Modal';


const App = () => {

  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const [cookies, setCookie] = useCookies(['user']);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // prevents flashing
    gsap.to('body', 0, { css: { visibility: 'visible' } });

    // timeline
    const tl = gsap.timeline();

    if(typeof cookies.userSeen === 'undefined') {


      tl.to('#loader', 1, {
        opacity: 0,
        ease: "power4.out",
        delay: 1,
        stagger: {
          amount: 0.3
        }
      }).from(".line span", 1.8, {
        y: 100,
        ease: "power4.out",
        delay: 1,
        skewY: 7,
        stagger: {
          amount: 0.3
        }
      }).to('.overlay-top', 1.6, {
        height: 0,
        delay: -.4,
        ease: 'expo.inOut',
        stagger: {
          amount: .3
        }
      }).to('.overlay-bottom', 1.6, {
        width: 0,
        ease: 'expo.inOut',
        delay: -.8,
        stagger: {
          amount: .3
        }
      }).to('.intro-overlay', 0, { css: { display: 'none' } })
        .to('#loader', 0, { css: { display: 'none' } });
  
      setCookie('userSeen', true, { path: '/', maxAge: 259200, secure: true});

    } else {
        tl.to('.intro-overlay', 0, { css: { display: 'none' } })
        .to('#loader', 0, { css: { display: 'none' } });
    }
    
   

  }, []);


  return (
    <div className="global-container">
      <div id="loader">
        <h2>p.</h2>
      </div>
      <IntroOverlay />
      <Banner />
      <Contact toggle={Toggle} />
      {modal ? <Modal close={Toggle} /> : null }
    </div>
  )
}

export default App
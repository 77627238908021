import React from 'react'
import Form from './Form'

const Modal = ({close}) => {
  return (
    <div id="modal-container">
      <div className="modal-wrapper">
        <div className="modal">
          <div className="container">
            <div className="row">
              <div className="modal-header">
                <h1>Hey! Tell Me <br /> Everything</h1>
                <span className="close-btn" onClick={() => close()}>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div className="modal-body">
                <Form />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
import React from 'react';
import { ReactComponent as Twitter } from '../assets/img/twitter.svg';
import { ReactComponent as LinkedIn } from '../assets/img/linkedin.svg';
import { ReactComponent as Github } from '../assets/img/github.svg';

const Contact = ({toggle}) => {

  const year = new Date().getFullYear();

  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="contact-body">
            <h2>Have an idea?</h2>
            <a onClick={() => toggle()}>Tell me about it</a>
          </div>
          <div className="contact-footer">
            <div className="row">
              <div className="contact-footer-left">
                <div className="contact-mail">
                  <a href="mailto:hello@pradeepgokul.com">
                    <span>hello<span>(at)</span>pradeepgokul.com</span>
                  </a>
                </div>
                {/* <div className="contact-link">
                  <a href="/privacy">Privacy Policy</a>
                </div> */}
              </div>
              <div className="contact-footer-right">
                <a href="https://twitter.com/pradeepg0kul">
                  <Twitter />
                </a>
                <a href="https://www.linkedin.com/in/pradeepgokul/">
                  <LinkedIn />
                </a>
                <a href="https://github.com/pradeepgokul">
                  <Github />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section">
        <span>Pradeep Gokul &copy; {year}</span>
      </div>
    </section>
  )
}

export default Contact;
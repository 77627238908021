import React, { useState } from 'react';
import { omit } from 'lodash';


const Form = () => {

    const [inputs, setInputs] = useState({ name: '', email: '' });
    const [errors, setErrors] = useState({ name: '', email: '' });

    const handleSubmit = (event) => {
        event.preventDefault();
        validate(inputs);
        if(Object.keys(errors).length === 0) {
            console.log('form validated')
        } else {
            console.log('form error');
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
        validate(inputs);
    }

    const validate = (input) => {
        
        if (!input.name) {
            setErrors(errors => ({ ...errors, name: 'Please fill your name' }))
        } else {
            setErrors(omit(errors, 'name'));
        }

        if (!input.email) {
            setErrors(errors => ({ ...errors, email: 'Please fill your email address' }))
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(input.email)) {
            setErrors(errors => ({ ...errors, email: 'That email address looks a bit weird' }))
        } else {
            setErrors(omit(errors, 'email'));
        }

    }

    return (
        <form className="contact-form" onSubmit={handleSubmit} noValidate >
            <div className="form-group">
                <div className="input_value">
                    <input type="text" placeholder="What's your name?" id="name" name="name" onChange={handleInputChange} value={inputs.name} />
                    <div className="input_line"></div>
                </div>
                {errors.name && <div className="input_message">{errors.name}</div>}
            </div>
            <div className="form-group">
                <div className="input_value">
                    <input type="email" id="email" placeholder="What's your email?" name="email" onChange={handleInputChange} value={inputs.email} />
                    <div className="input_line"></div>
                </div>
                {errors.email && <div className="input_message">{errors.email}</div>}
            </div>
            <div className="form-group">
                <div className="input_value">
                    <textarea name="message" placeholder="Tell me everything..." id="message" name="message" onChange={handleInputChange} value={inputs.message}></textarea>
                    <div className="input_line"></div>
                </div>
            </div>
            <div className="form-submit">
                <button type="submit" className="btn btn-send">
                    <span>Send Message</span>
                </button>
            </div>
        </form>
    )
}

export default Form;
import React from 'react';
import wave from '../assets/img/hello.png';

const Banner = () => {
    return (
        <section className="main">
            <div className="container">
                <div className="row">
                    <div className="wave">
                        <img src={wave} alt="wave" />
                    </div>
                    <h2>
                        <div className="line">
                            <span>Hello! I'm Pradeep</span>
                        </div>
                        <div className="line">
                            <span>I build things on the internet!</span>
                        </div>
                    </h2>
                </div>
            </div>
        </section>
    )
}

export default Banner